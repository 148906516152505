<template>
  <div></div>
</template>

<script>
export default {
  name: 'Default',
  created () {
    this.$store.commit('fullPage', true)
    this.$store.commit('asideRightToggle', false)
  },
  mounted () {
    this.$store.dispatch('user/fetch')
      .then(() => {
        this.$store.commit('fullPage', false)
        this.$router.push({ name: 'dashboard' })
      })
  }
}
</script>
