<template>
  <div class="has-text-centered">
    <component :is="componentIs">
      <div class="error-page-icon">
        <b-icon icon="power-plug" custom-size="default"/>
      </div>
      <div class="error-page-caption">
        <h1>Sample error</h1>
      </div>
      <div class="error-page-action">
        <div class="buttons is-centered">
          <button type="button" @click="goBack" class="button is-primary">
            Go Back
          </button>
          <slot name="buttons"/>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Error',
  components: { CardComponent },
  props: {
    isInCard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    componentIs () {
      return this.isInCard ? 'card-component' : 'div'
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
