<template>
  <card-component title="Confirm Password Reset" icon="lock-reset">
    <form @submit.prevent="submit" method="POST">
      <b-field label="Email Address" message="Enter email assocaited with your Bunya account.">
        <b-input type="email" name="email" v-model="form.email" placeholder="name@domain.com" required autofocus/>
      </b-field>
      <b-field label="New Password" message="Enter a new password.">
        <b-input type="password" name="password" v-model="form.password" placeholder="********" required/>
      </b-field>
      <b-field label="Confirm Password" message="Confirm password.">
        <b-input type="password" name="passwordConfirm" v-model="form.password_confirmation" placeholder="********" required/>
      </b-field>
      <hr>
      <b-field grouped>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }" :disabled="!form.password">
            Update Password
          </button>
        </div>
        <div class="control">
          <router-link :to="{ name: 'login' }" class="button is-primary is-outlined">
            Return To Login
          </router-link>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>

import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordReset',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      }
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.$store.dispatch('user/confirmPasswordReset', this.form)
        .then(() => {
          this.form.email = null
          this.isLoading = false
          this.$router.push({ name: 'login' }).then(() => {
            this.$buefy.toast.open({
              message: 'Password successfully updated.',
              type: 'is-success'
            })
          })
        })
        .catch(e => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  },
  mounted () {
    this.form.token = this.passwordResetToken
  },
  props: ['passwordResetToken']
}
</script>

<style scoped>

</style>
