<template>
  <card-component title="Forgot Password?">
    <p>Don’t worry it happens. Please enter the email address associated with your account.</p>
    <form @submit.prevent="submit" method="POST" v-if="!requestSent">
      <b-field label="E-mail Address" message="E-mail used for registration">
        <b-input type="email" name="email" v-model="form.email" placeholder="name@domain.com" required autofocus/>
      </b-field>
      <hr>
      <b-field grouped>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}" :disabled="!form.email">
            Send Reset Link
          </button>
        </div>
        <div class="control">
          <router-link to="/login" class="button is-primary is-outlined">
            Back
          </router-link>
        </div>
      </b-field>
    </form>
    <div v-if="requestSent">
      <div class="notification is-success is-light">
        Your password reset request has been received.
        Please check your email for a link to continue resetting your password.
      </div>
      <router-link to="/login" class="button is-primary is-outlined">
        Return To Login
      </router-link>
    </div>
  </card-component>

</template>

<style>

  .card-header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #2D3958;
    padding: 0rem;
    padding-bottom: 15px;
  }

  .card-content {
    padding: 0rem;
    padding-top: 15px;
}

  .card {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 10px 30px rgb(0 49 55 / 10%) !important;
    box-shadow: 0px 10px 30px rgb(0 49 55 / 10%) !important;
    border-radius: 23px !important;
    padding: 50px 40px 40px;
    z-index: 5;
}

  .hero.is-fullheight {
    background: linear-gradient(180deg, #403E6B 0%, #2D2C4F 100%);
  }

</style>

<script>

import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordReset',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      requestSent: false,
      form: {
        email: ''
      }
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.$store.dispatch('user/sendPasswordResetLink', this.form)
        .then(() => {
          this.form.email = null
          this.isLoading = false
          this.requestSent = true
        })
        .catch(e => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
