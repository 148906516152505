<template>
  <div>
    <div class="columns big-login-box">
      <div class="column login-left">
      <div class="loginbox">
            <img class="login-logo is-hidden-touch" src="../../assets/Bunya-logo.svg">
            <img class="login-logo-full is-hidden-desktop" src="../../assets/bunya_logo_full_coloured.svg">
            <div class="login-form">
            <div class="title">Welcome back</div>
            <p class="login-sub-text">Please sign in to your account.</p>
            <b-notification type="is-danger is-light" has-icon :closable="false" v-if="loginFailed">{{ loginFailedMessage }}</b-notification>
            <form @submit.prevent="submit" method="POST">
              <b-field label="Email Address" :type="validationErrors.email ? 'is-danger' : ''" :message="validationErrors.email">
                <b-input name="email" type="email" v-model="email" autofocus/>
              </b-field>
              <b-field label="Password" class="password-padding" :type="validationErrors.password ? 'is-danger' : ''" :message="validationErrors.password">
                <b-input type="password" name="password" v-model="password"/>
              </b-field>
              <b-field class="no-margin-bottom level-right">
                <router-link :to="{ name: 'password-reset' }" class="forgot">
                  Forgot your password?
                </router-link>
              </b-field>
              <b-field grouped>
                <button type="submit" class="button is-primary login-button" :class="{ 'is-loading': isLoading }">
                  Login
                </button>
              </b-field>
            </form>
            <div class="sign-up-footer">
        <div class="level is-mobile">
          <div class="level-item justify-center">
            <a class="login-link" href="https://getbunya.com/">Don't have an account? <b>Create one</b></a>
          </div>
        </div>
      </div>
          </div>
        </div>
    </div>
    <div class="column login-right is-hidden-touch">
      <img class="login-background-logo" src="../../assets/bunya_logo_full_white.svg">
      <img class="login-image" src="https://uploads-ssl.webflow.com/60f1b7e1bf08a4778d219ddd/60f1b7e1bf08a4cdba219f36_home_hero.png" alt="Paris">
    </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 500px) and (max-width: 1023px) {
  //MARC Padding before screen
  .login-left[data-v-21967f1d] {
    border-radius: 30px 30px 30px 30px !important;
    //background-color: crimson;
  }
  .columns {
    max-height: 720px;
    //margin: 100px !important;
  }
}
  .title {
  font-size: 28px;
  color: #201f39;
  margin-bottom: 10px;
  }
  .login-sub-text {
  font-size: 20px;
  color: #9190A0;
  margin-bottom: 30px;
  }
  .login-logo {
  padding-bottom: 30px;
  }
  .login-logo-full {
  padding-bottom: 30px;
  height: 70px;
  }
  .login-background-logo {
    width: 180px;
    margin-bottom: 40px;
  }
  .big-login-box {
    //background: #2D2C4E;
    box-shadow: 0 2px 120px 0 rgba(0,0,0,0.20);
    border-radius: 30px !important;
    //border-radius: 30px;
    //max-width: 1400px;
    width: 100%;
    height: 80vh;
    //max-width: 500px;
    //height: 100%;
  }
.login-left {
  background: #ffffff;
  border-radius: 30px 0px 0px 30px !important;
  //padding-left: 90px;
  //padding-right: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  height: 100%;
  max-width: 450px;
  //height: 90vh;
}
.login-right {
  background: #2D2C4E;
  border-radius: 0px 30px 30px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px !important;
  background-image: url("../../assets/login_background.svg");
  background-repeat:no-repeat;
  background-position: bottom;
  background-size: 100%;
  //height: 460px
  //width: ?px;
}
.login-image {
  width: 100%;
  max-width: 620px !important;
}
.login-form {
  //max-width: 400px;
  //min-width: 380px;
  //width: 300px;
}
  .login-box {
    //border-radius: 30px 0px 0px 30px !important;
  }
  .login-button {
    margin-top: 44px;
    background: #E84C62;
    border-radius: 10px;
    height: 60px;
    width: 100%;
  }
  .sign-up-footer {
    text-align: center;
    padding-top: 30px !important;
    //padding: 15px;
    position: relative;
    z-index: 5;
  }
  .justify-left {
    justify-content: left;
  }
  .justify-right {
    justify-content: right;
  }

  .big-login-box {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    .login-box {
      margin-top: 0px;
    }
  }

   //MOBILE AND TABLET RESPONSIVE FIX
   @media only screen and (max-width: 500px) {
    .login-left {
    height: 100vh;
    border-radius: 0px !important;
    }
    .big-login-box {
    border-radius: 0px !important;
    }
   }

   //MOBILE AND TABLET RESPONSIVE FIX
  @media only screen and (max-width: 1023px) {
    .big-login-box {
    max-width: 700px !important;
    //border-radius: 30px;
  }
  .login-left {
  background: #ffffff;
  //border-radius: 30px !important;
  }
}

</style>

<script>

import { Device } from '@capacitor/device'

export default {
  name: 'Login',
  components: {},
  computed: {
    url () {
      return process.env.VUE_APP_ROOT_API + 'login'
    },
    payload () {
      return {
        password: this.password,
        email: this.email,
        deviceName: this.deviceName
      }
    },
    isMobileApp () {
      return process.env.NODE_ENV === 'mobile'
    },
    emailType () {
      if (this.loginFailed) {
        return 'is-danger'
      }
      return null
    },
    emailMessage () {
      if (this.loginFailed) {
        return this.loginFailedMessage ?? 'Invalid login details.'
      }
      return null
    }
  },
  mounted () {
    Device.getInfo().then(deviceInfo => {
      if (deviceInfo.name) {
        this.deviceName = deviceInfo.name
      }
    })
  },
  data () {
    return {
      isLoading: false,
      email: '',
      password: '',
      loginFailed: false,
      loginFailedMessage: null,
      validationErrors: [],
      deviceName: 'My Mobile Device',
      remember: false
    }
  },
  methods: {
    submit () {
      this.loginFailed = false
      this.isLoading = true
      if (this.isMobileApp) {
        this.$store.dispatch('user/mobileLogin', this.payload)
          .then(() => {
            this.isLoading = false
            this.$router.push('/')
          })
          .catch((e) => {
            this.loginFailed = true
            this.loginFailedMessage = e.response.data.message ?? 'Login failed.'
            this.validationErrors = e.response.data.errors ?? []
            this.isLoading = false
          })
      } else {
        this.$store.dispatch('user/webLogin', this.payload)
          .then(() => {
            this.isLoading = false
            this.$router.push('/')
          })
          .catch((e) => {
            this.loginFailed = true
            this.loginFailedMessage = e.response.data.message ?? 'Login failed.'
            this.validationErrors = e.response.data.errors ?? []
            this.isLoading = false
          })
      }
    }
  }
}
</script>
