<template>
  <section class="section hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullPage',
  created () {
    this.$store.commit('fullPage', true)
    this.$store.commit('asideRightToggle', false)
  },
  beforeDestroy () {
    this.$store.commit('fullPage', false)
  }
}
</script>
<style lang="scss" scoped>

//MOBILE AND TABLET RESPONSIVE FIX
@media only screen and (max-width: 1023px) {
  .hero-body {
  flex-direction: column;
  }
}

//FIXING PADDING ALLROUND
.container {
  padding: 0px !important;
  }
  .hero-body {
    padding: 0px !important;
  }
  .section {
    padding: 0px;
}
  .column {
    padding: 0px;
  }
  .columns {
    margin: 0px;
}

</style>
